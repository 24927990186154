import { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { ResponseSelection } from "../types/assessmentTypes";
import { v4 as uuidv4 } from "uuid";

const AppContext = createContext<{
    responseSelectionData: ResponseSelection[];
    setResponseSelectionData: React.Dispatch<React.SetStateAction<ResponseSelection[]>>;
    currentQuestionIndex: number;
    setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
    showDescription: boolean;
    setShowDescription: React.Dispatch<React.SetStateAction<boolean>>;    
    showResultsInstructions: boolean;
    setShowResultsInstructions: React.Dispatch<React.SetStateAction<boolean>>;    
    showQuestionDescription: boolean;
    setShowQuestionDescription: React.Dispatch<React.SetStateAction<boolean>>;    
    guid: string | null;
    responseCompleteCount: number;
    tacDateAgreed: string | null;
    setTacDateAgreed: React.Dispatch<React.SetStateAction<string | null>>;
}>({
    responseSelectionData: [],
    setResponseSelectionData: () => {},
    currentQuestionIndex: 0,
    setCurrentQuestionIndex: () => {},
    showDescription: true,
    setShowDescription: () => {},
    showResultsInstructions: true,
    setShowResultsInstructions: () => {},
    showQuestionDescription: false,
    setShowQuestionDescription: () => {},
    guid: "",
    responseCompleteCount: 0,
    tacDateAgreed: null,
    setTacDateAgreed: () => {}
}); 

export const useAamContent = () => {
  return useContext(AppContext);
};

type ContextProviderProps = {
    children: ReactNode; // Add this line to include 'children' prop in the type definition
};

export const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {

  const guid = localStorage.getItem("guid");
  const initialTacDateAgreed = localStorage.getItem("tacDateAgreed");
  
  const initialResponseSelectionData = localStorage.getItem("responseSelectionData")
    ? JSON.parse(localStorage.getItem("responseSelectionData") as string)
    : [];

  //console.log (guid)
  //console.log (initialTacDateAgreed)
  
  //const [responseSelectionData, setResponseSelectionData] = useState<ResponseSelection[]>([]);
  const [responseSelectionData, setResponseSelectionData] = useState<ResponseSelection[]>(initialResponseSelectionData);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [showDescription, setShowDescription] = useState<boolean>(true); 
  const [showResultsInstructions, setShowResultsInstructions] = useState<boolean>(true); 
  const [showQuestionDescription, setShowQuestionDescription] = useState(false);
  const [responseCompleteCount, setResponseCompleteCount] = useState<number>(0);
  const [tacDateAgreed, setTacDateAgreed] = useState<string | null>(initialTacDateAgreed);

  useEffect(() => {
    if (!guid) {
      const newGuid = uuidv4();
      localStorage.setItem('guid', newGuid);
    }
  }, [guid]);

  useEffect(() => {
    const count = responseSelectionData.filter(item => item.selection !== '0').length;
    setResponseCompleteCount(count);
    if (responseSelectionData.length > 0) {
      localStorage.setItem("responseSelectionData", JSON.stringify(responseSelectionData));
    } else {
      localStorage.removeItem("responseSelectionData");
    }
  }, [responseSelectionData]); 

  useEffect(() => {
    if (tacDateAgreed) {
      localStorage.setItem("tacDateAgreed", tacDateAgreed);
    } else {
      localStorage.removeItem("tacDateAgreed");
    }
  }, [tacDateAgreed]);

  return (
    <AppContext.Provider
        value={{ responseSelectionData, 
                setResponseSelectionData, 
                currentQuestionIndex, 
                setCurrentQuestionIndex,
                showDescription,
                setShowDescription,
                showResultsInstructions,
                setShowResultsInstructions,
                showQuestionDescription, 
                setShowQuestionDescription,
                guid,
                responseCompleteCount,
                tacDateAgreed,
                setTacDateAgreed
              }}
    >
      {children}
    </AppContext.Provider>
  );
};