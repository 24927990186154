import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LazyLoad from './LazyLoad';
import logo from './logo_black_back_small.png'; // Import your logo here

// import { faUser, faCaretDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './App.css';

//import { auth } from './functions/firebase';
//import { getAnalytics } from "firebase/analytics";
//import { onAuthStateChanged, User, signOut } from 'firebase/auth';

import { ContextProvider } from "./components/AppContext";

//const analytics = getAnalytics(app);

const App: React.FC = () => {  

  const [menuVisible, setMenuVisible] = useState(false);

  /*
  const [user, setUser] = useState<User | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false); 
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };
  */

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const menu = document.querySelector('nav');
    const menuButton = document.querySelector('.hamburger-menu');
  
    if (menu && menuButton && !menu.contains(target) && !menuButton.contains(target)) {
      setMenuVisible(false);
    }
  };  

  /*
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  */

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  //const { user } = useAuth();

  //   User ID: {user.uid}

  return (
    <Router>
      <ContextProvider>
        <header>
          <Link to="/">
            <img src={logo} alt="Ask A Machine" className="logo" />
          </Link>
          <span className="title-mobile">Ask a Machine</span>
          <span className="title">Ask a Machine - Career Assessment</span>
          <HamburgerMenuIcon onClick={() => setMenuVisible(!menuVisible)} />
          <nav className={menuVisible ? "visible" : ""}>            
            <ul>
              <li>
                <Link onClick={() => setMenuVisible(false)} to="/">Home</Link>
              </li>
              <li>
                <Link onClick={() => setMenuVisible(false)} to="/assessment">Assessment</Link>
              </li>
              <li>
                <Link onClick={() => setMenuVisible(false)} to="/results">Results</Link>
              </li>
              <li>
                <Link onClick={() => setMenuVisible(false)} to="/about">About</Link>
              </li>
              <li>
                <Link onClick={() => setMenuVisible(false)} to="/contact">Contact</Link>
              </li>
              {/* user ? (
                <li>
                  <div className="user-menu" onClick={() => setDropdownVisible(!dropdownVisible)}>
                    <div className="user-icon">
                      <FontAwesomeIcon icon={faUser} />  
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                  {dropdownVisible && (
                    <div className="dropdown-menu">
                      <p>User: {user.displayName}</p>
                      <button onClick={handleSignOut}>Sign Out</button>
                    </div>
                  )}      
                </li>
              ) : (
                <li>
                  <Link onClick={() => setMenuVisible(false)} to="/signin">Sign&nbsp;In</Link>
                </li>
              ) */}
            </ul>
          </nav>
        </header>
        <div className="content">
          <Routes>
            <Route path="/" element={<LazyLoad component={() => import('./Home')} />} />
            <Route path="/assessment" element={<LazyLoad component={() => import('./Assessment')} />} />
            <Route path="/results" element={<LazyLoad component={() => import('./Results')} />} />
            <Route path="/about" element={<LazyLoad component={() => import('./About')} />} />
            <Route path="/contact" element={<LazyLoad component={() => import('./Contact')} />} />
            {/*<Route path="/signin" element={<LazyLoad component={() => import('./components/SignIn')} />} />*/}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <footer>
          <p>© 2023 Ask a Machine.</p>
        </footer>        
      </ContextProvider>
    </Router>
  );
};

const NotFound: React.FC = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};
 
const HamburgerMenuIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button className="hamburger-menu" onClick={onClick}>
      <span><FontAwesomeIcon icon={faBars} /></span>
      <span></span>
      <span></span>
    </button>
  );
}; 

export default App;
          
