import React, { lazy, Suspense, ComponentType } from 'react';

interface LazyLoadProps {
  component: () => Promise<{ default: ComponentType<any> }>;
  fallback?: React.ReactNode;
}

const LazyLoad: React.FC<LazyLoadProps> = ({ component, fallback = null }) => {
  const LazyComponent = lazy(component);

  return (
    <Suspense fallback={fallback || <div>Loading...</div>}>
      <LazyComponent />
    </Suspense>
  );
};

export default LazyLoad;